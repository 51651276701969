import {createReducer} from '@reduxjs/toolkit'
import {checkInstalledAppsWixCodeApi, checkInstalledAppsWixSDK} from '../actions/installed-apps'
import {InstalledAppsState} from '../types/state'

const initialState: InstalledAppsState = {
  members: false,
  schedule: false,
  groups: false,
  paidPlans: false,
  eventsInMembersInstalled: false,
  giftCards: false,
}

export const installedApps = createReducer(initialState, builder => {
  builder.addCase(checkInstalledAppsWixSDK.fulfilled, (state, {payload}) => ({...state, ...payload}))
  builder.addCase(checkInstalledAppsWixCodeApi.fulfilled, (state, {payload}) => ({...state, ...payload}))
})
